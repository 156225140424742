import classNames from 'classnames';
import isFunction from 'lodash/isFunction';
import throttle from 'lodash/throttle';
import React, { cloneElement, isValidElement, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { ReactElement } from 'react-markdown/lib/react-markdown';

import { CARET_LARGE } from '@/components/switchback/Icon/assets';
import Icon from '@/components/switchback/Icon/IconComponent';
import lineClampConfig from '@/config/lineClampConfig';
import { contentOverflows, lineIsClamped } from '@/utility/helpers';
import { mergeRefs } from '@/utility/mergeRefs';

import FormattedPlaintext from '../FormattedPlaintext/FormattedPlaintext';
import css from './ClampedText.module.css';

type TDeadNode = boolean | undefined | null;
type TClampedTextChildren = string | TDeadNode | [string | TDeadNode, ...Array<React.ReactNode>];

const findPlaintext = (children?: TClampedTextChildren) => {
  if (!children || children === true) return undefined;
  const text = Array.isArray(children) ? children[0] : children;
  if (!text || text === true) return undefined;
  if (isValidElement(children)) return children as ReactElement;
  return text;
};

// Arrays with line clamp classes according to breakpoint
const bpSmall: Array<string> = [];
const bpMedium: Array<string> = [];
const bpLarge: Array<string> = [];
for (let i = 0; i < lineClampConfig.count; i++) {
  const classname = `${lineClampConfig.classname}-${i}`;
  bpSmall.push(classname);
  bpMedium.push(`md:${classname}`);
  bpLarge.push(`lg:${classname}`);
}

const getClasses = (clamp: number | Array<number | null>) => {
  if (typeof clamp === 'number') return [bpSmall[clamp]];

  const sm = clamp[0] ? bpSmall[clamp[0]] : '';
  const md = clamp[1] ? bpMedium[clamp[1]] : '';
  const lg = clamp[2] ? bpLarge[clamp[2]] : '';
  return [sm, md, lg];
};

type IClampedTextElement = React.HTMLAttributes<HTMLElement>;

interface IProps {
  /**
   * One fixed clamp number to rule them all or an array of up to three values [small, medium, large]
   */
  clamp?: number | Array<number>;
  children?: TClampedTextChildren;
  readLess?: boolean;
  readMore?: boolean;
  readMoreAriaLabel?: string;
  readLessAriaLabel?: string;
  paragraphClasses?: string | ((text: string, index?: number) => string);
  linkWithIcon?: boolean;
  buttonClassName?: string;
  underline?: boolean;
}

const ClampedText = React.forwardRef<HTMLParagraphElement, IProps & IClampedTextElement>(
  (
    {
      children,
      clamp,
      linkWithIcon = false,
      readLess,
      readMore,
      readMoreAriaLabel,
      readLessAriaLabel,
      paragraphClasses,
      buttonClassName: className,
      underline = true,
    },
    ref,
  ) => {
    const intl = useIntl();
    const textRef = useRef<HTMLDivElement | null>(null);
    const [mayClamp, setMayClamp] = useState<boolean | null>(null);
    const [isClamped, setIsClamped] = useState<boolean>(true);
    const [isSubcontentClamped, setIsSubcontentClamped] = useState<boolean>(true);
    const classes = clamp ? getClasses(clamp) : [];

    const text = findPlaintext(children);

    const totalParagraphs = isValidElement(text) ? [] : text?.split(/\s*\n\s*\n\s*/);

    const extendedParagraphClasses = (text: string, i: number) => {
      if (!Array.isArray(children) && totalParagraphs && totalParagraphs.length - 1 === i) {
        return `${css.text}`;
      }

      const computedParagraphClasses =
        paragraphClasses && isFunction(paragraphClasses)
          ? paragraphClasses(text, i)
          : paragraphClasses;
      return `${computedParagraphClasses} ${css.text}`;
    };
    const paragraphDataAttrs = {
      clamped: isClamped,
    };
    const paragraphAriaAttrs = {
      hidden: (_: string, i: number) => !!isClamped && i > 0,
    };

    // If we have additional potentially non-text children, render them after the fold
    let subContent: React.ReactNode[] = [];
    if (Array.isArray(children)) {
      const rest = children.slice(1);
      if (rest.length && typeof rest !== 'string') {
        subContent = rest.filter(Boolean).map((child, index) => {
          if (
            isValidElement<{ dataClamped: boolean; ariaHidden: boolean; className?: string }>(
              child,
            ) &&
            child.type === 'p'
          ) {
            return cloneElement(child, {
              key: index,
              dataClamped: isSubcontentClamped,
              ariaHidden: !!isSubcontentClamped && index > 0,
              className: extendedParagraphClasses('', 0),
            });
          }
          return (
            <p
              data-clamped={isSubcontentClamped}
              aria-hidden={!!isSubcontentClamped && index > 0}
              key={index}>
              {child}
            </p>
          );
        });
      }
    }

    const hasSubContent = !!subContent.length;

    useEffect(() => {
      if (!textRef.current) {
        return;
      }

      const handleResize = throttle(() => {
        setMayClamp(contentOverflows(textRef.current));
      }, 100);

      handleResize();

      window.addEventListener('resize', handleResize);
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);

    useEffect(() => {
      if (!textRef.current) return;

      if (mayClamp === null) {
        setMayClamp(contentOverflows(textRef.current));
      }

      setIsClamped(lineIsClamped(textRef.current));
    }, [mayClamp, textRef]);

    if (!clamp || !children) return null;

    const toggleContent = () => {
      if (mayClamp) setIsClamped(prev => !prev);
      setIsSubcontentClamped(prev => !prev);
    };

    return (
      <>
        <div
          className={`${isClamped ? classes.join(' ') : ''} break-words pb-0.5`}
          ref={mergeRefs(ref, textRef)}>
          {!!text && (
            <FormattedPlaintext
              text={text}
              paragraphClasses={extendedParagraphClasses}
              paragraphDataAttrs={paragraphDataAttrs}
              paragraphAriaAttrs={paragraphAriaAttrs}
            />
          )}
        </div>
        {hasSubContent && (
          <div
            style={{ display: isSubcontentClamped ? 'none' : 'block' }}
            aria-hidden={!!isSubcontentClamped}
            className={`${isSubcontentClamped ? classes.join(' ') : ''} pb-0.5`}>
            {subContent}
          </div>
        )}
        {((mayClamp && readMore && isClamped) || (hasSubContent && isSubcontentClamped)) && (
          <button
            aria-label={readMoreAriaLabel}
            data-testid="read-more-btn"
            className={classNames([
              'inline-grid grid-flow-col gap-1 before-focus-style mt-2 hover:no-underline',
              {
                underline: underline,
              },
              className,
            ])}
            onClick={toggleContent}>
            {intl.formatMessage({
              description: 'ClampedText - Read more label',
              defaultMessage: 'Read more',
              id: 'H/9J6H',
            })}

            {linkWithIcon && (
              <Icon width={10} className="mt-1 transform -rotate-90" name={CARET_LARGE} />
            )}
          </button>
        )}
        {((mayClamp && readLess && !isClamped) || (hasSubContent && !isSubcontentClamped)) && (
          <button
            aria-label={readLessAriaLabel}
            data-testid="read-less-btn"
            className={classNames([
              'inline-grid grid-flow-col gap-1 before-focus-style mt-2 hover:no-underline',
              {
                underline: underline,
              },
              className,
            ])}
            onClick={toggleContent}>
            {intl.formatMessage({
              description: 'ClampedText - Read less label',
              defaultMessage: 'Read less',
              id: 'jjzXn6',
            })}

            {linkWithIcon && (
              <Icon width={10} className="mt-1 transform rotate-90" name={CARET_LARGE} />
            )}
          </button>
        )}
      </>
    );
  },
);

ClampedText.displayName = 'ClampedText';

export default ClampedText;
